const updateQueryStringParameter = (uri, key, value) => {
  // Updates or adds a query string parameter in a given URI
  let re = new RegExp('([?&])' + key + '=.*?(&|$)', 'i');
  let separator = uri.indexOf('?') !== -1 ? '&' : '?';
  if (uri.match(re)) {
    return uri.replace(re, '$1' + key + '=' + value + '$2');
  } else {
    return uri + separator + key + '=' + value;
  }
}

const readCookie = (name) => {
  // Reads a cookie by name and returns its value
  let nameEQ = encodeURIComponent(name) + '=';
  let ca = document.cookie.split(';');
  for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == ' ') c = c.substring(1, c.length);
      if (c.indexOf(nameEQ) == 0) return decodeURIComponent(c.substring(nameEQ.length, c.length));
    }
  return null;
}

const addGidToUrl = (gid) => {
  const pageUrl = window.location.href;
  if (!pageUrl.includes('gid=') && readCookie('_up4gid') !== gid){
    window.location.href = updateQueryStringParameter(pageUrl, 'gid', gid);
  }
}